/* The animation appears only for empty div. */
#online-top-area-root {
    height: var(--top-nav-height, 56px);
}
    #online-top-area-root.top-area-with-banner {
        height: var(--top-nav-height, 90px);
    }

@media only screen and (min-width: 900px) {
    #online-top-area-root {
        height: var(--top-nav-height, 80px);
    }
        #online-top-area-root.top-area-with-banner {
            height: var(--top-nav-height, 114px);
        }
}
